var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-2 ml-n3", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: { group: "" },
                      model: {
                        value: _vm.toggle_exclusive,
                        callback: function ($$v) {
                          _vm.toggle_exclusive = $$v
                        },
                        expression: "toggle_exclusive",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.filter != "all",
                              expression: "this.filter != 'all'",
                            },
                          ],
                          attrs: {
                            ripple: _vm.high,
                            elevation: _vm.high ? 1 : 0,
                            width: "160",
                          },
                          on: { click: _vm.highAlert },
                        },
                        [
                          _c("span", { staticClass: "hidden-sm-and-down" }, [
                            _vm._v("High Alert"),
                          ]),
                          _c(
                            "v-avatar",
                            {
                              staticClass: "shadow-primary",
                              attrs: {
                                color:
                                  "bg-gradient-primary border-radius-xl ml-2 mt-0",
                                height: "24",
                                width: "24",
                                "min-width": "12",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "material-icons-round text-white",
                                  attrs: { size: "16" },
                                },
                                [_vm._v("priority_high")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.filter != "all",
                              expression: "this.filter != 'all'",
                            },
                          ],
                          attrs: {
                            ripple: _vm.medium,
                            elevation: _vm.medium ? 1 : 0,
                            width: "160",
                          },
                          on: { click: _vm.mediumAlert },
                        },
                        [
                          _c("span", { staticClass: "hidden-sm-and-down" }, [
                            _vm._v("Medium Alert"),
                          ]),
                          _c(
                            "v-avatar",
                            {
                              staticClass: "shadow-warning",
                              attrs: {
                                color:
                                  "bg-gradient-warning border-radius-xl ml-2 mt-0",
                                height: "24",
                                width: "24",
                                "min-width": "12",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "material-icons-round text-white",
                                  attrs: { size: "16" },
                                },
                                [_vm._v("warning")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.filter != "all",
                              expression: "this.filter != 'all'",
                            },
                          ],
                          attrs: {
                            ripple: _vm.all,
                            elevation: _vm.all ? 1 : 0,
                            width: "160",
                          },
                          on: { click: _vm.allSites },
                        },
                        [
                          _c("span", { staticClass: "hidden-sm-and-down" }, [
                            _vm._v("All Sites"),
                          ]),
                          _c(
                            "v-avatar",
                            {
                              staticClass: "shadow-success",
                              attrs: {
                                color:
                                  "bg-gradient-success border-radius-xl ml-2 mt-0",
                                height: "24",
                                width: "24",
                                "min-width": "12",
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "material-icons-round text-white",
                                  attrs: { size: "16" },
                                },
                                [_vm._v("dataset")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.filter != "all",
                              expression: "this.filter != 'all'",
                            },
                          ],
                          attrs: { elevation: "1" },
                        },
                        [
                          _c("span", [_vm._v("Count: ")]),
                          this.high
                            ? _c(
                                "v-avatar",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    color: "bg-gradient-primary",
                                    size: "30",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold text-white text-sm",
                                    },
                                    [_vm._v(_vm._s(_vm.c))]
                                  ),
                                ]
                              )
                            : this.medium
                            ? _c(
                                "v-avatar",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    color: "bg-gradient-warning",
                                    size: "30",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold text-white text-sm",
                                    },
                                    [_vm._v(_vm._s(_vm.c))]
                                  ),
                                ]
                              )
                            : _c(
                                "v-avatar",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    color: "bg-gradient-success",
                                    size: "30",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-weight-bold text-white text-sm",
                                    },
                                    [_vm._v(_vm._s(_vm.c))]
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "card-shadow border-radius-xl" },
        [
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c("v-data-table", {
                ref: "datatable",
                staticClass: "table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.sites,
                  "single-expand": _vm.singleExpand,
                  expanded: _vm.expandedIds,
                  "item-key": "id",
                  "show-expand": "",
                  search: _vm.search,
                  "custom-filter": _vm.customSearch,
                  page: _vm.page,
                  "hide-default-footer": "",
                  "items-per-page": _vm.itemsPerPage,
                  "mobile-breakpoint": "0",
                },
                on: {
                  "update:expanded": function ($event) {
                    _vm.expandedIds = $event
                  },
                  "current-items": _vm.getFiltered,
                  "update:page": function ($event) {
                    _vm.page = $event
                  },
                  "page-count": function ($event) {
                    _vm.pageCount = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "", height: "80" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "5" } },
                                  [
                                    _c(
                                      "v-text-field",
                                      {
                                        staticClass:
                                          "\n                     input-style\n                     font-size-input\n                     text-light-input\n                     placeholder-light\n                     input-icon\n                   ",
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          flat: "",
                                          filled: "",
                                          solo: "",
                                          height: "43",
                                          placeholder: "Search",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "prepend-inner" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "material-icons-round mt-n2",
                                                attrs: {
                                                  color: "#adb5bd",
                                                  size: "16",
                                                },
                                              },
                                              [_vm._v("search")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.expandAll()
                                          },
                                        },
                                      },
                                      [_vm._v("Expand All")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c(
                              "v-dialog",
                              {
                                attrs: { persistent: "", "max-width": "600px" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "\n                     font-weight-bold\n                     text-xs\n                     btn-primary\n                     bg-gradient-primary\n                   ",
                                                attrs: {
                                                  ripple: false,
                                                  elevation: 0,
                                                  height: "43",
                                                  id: "portal_excel",
                                                },
                                                on: { click: _vm.expandPage },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v(" Export Report ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.reportDialog,
                                  callback: function ($$v) {
                                    _vm.reportDialog = $$v
                                  },
                                  expression: "reportDialog",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "card-shadow border-radius-xl",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-header-padding card-border-bottom",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold text-h5 text-typo mb-0",
                                          },
                                          [_vm._v(" Export Report ")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ml-12" },
                                      [
                                        _c("v-date-picker", {
                                          attrs: {
                                            range: "",
                                            color: "pink",
                                            width: "500px",
                                            "show-adjacent-months": "",
                                          },
                                          model: {
                                            value: _vm.reportDatesRange,
                                            callback: function ($$v) {
                                              _vm.reportDatesRange = $$v
                                            },
                                            expression: "reportDatesRange",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "mt-6" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatReportDatesRange()
                                            ) +
                                            " "
                                        ),
                                        _c("br"),
                                        _c("v-select", {
                                          staticClass: "mt-4 mb-n2",
                                          attrs: {
                                            label:
                                              "Please select data to include",
                                            solo: "",
                                            items: _vm.optionsData,
                                          },
                                          model: {
                                            value: _vm.selected,
                                            callback: function ($$v) {
                                              _vm.selected = $$v
                                            },
                                            expression: "selected",
                                          },
                                        }),
                                        _c("v-select", {
                                          staticClass: "mb-n8",
                                          attrs: {
                                            label:
                                              "Please select time increment",
                                            solo: "",
                                            items: _vm.optionsTime,
                                          },
                                          model: {
                                            value: _vm.selectedStep,
                                            callback: function ($$v) {
                                              _vm.selectedStep = $$v
                                            },
                                            expression: "selectedStep",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-actions",
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "black",
                                              outlined: "",
                                            },
                                            on: {
                                              click: _vm.clearSitesCSVExport,
                                            },
                                          },
                                          [_vm._v(" Clear ")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "black",
                                              outlined: "",
                                            },
                                            on: {
                                              click: _vm.closeSitesCSVExport,
                                            },
                                          },
                                          [_vm._v(" Close ")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "\n                       font-weight-bold\n                       btn-ls btn-primary\n                       bg-gradient-primary\n                       py-3\n                       px-6\n                     ",
                                            attrs: {
                                              elevation: "0",
                                              ripple: false,
                                            },
                                            on: {
                                              click: _vm.exportSitesCSVReport,
                                            },
                                          },
                                          [_vm._v(" Export ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      (_vm.high || _vm.medium) &&
                                      _vm.user.claims.user_role == "super_user",
                                    expression:
                                      "(high || medium) && user.claims.user_role == 'super_user'",
                                  },
                                ],
                                staticClass:
                                  "\n                 font-weight-bold\n                 text-xs\n                 btn-success\n                 bg-gradient-success\n                 py-3\n                 px-6\n                 ms-3\n               ",
                                attrs: {
                                  disabled: _vm.validateDisabled,
                                  elevation: 0,
                                  ripple: false,
                                  height: "43",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.startValidateWizard()
                                  },
                                },
                              },
                              [_vm._v("Validate")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "header.ghi",
                    fn: function (ref) {
                      var header = ref.header
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c("span", _vm._g({}, on), [
                                        _vm._v(_vm._s(header.text)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Measured sun for this date")])]
                        ),
                      ]
                    },
                  },
                  {
                    key: "header.precipitation_rate",
                    fn: function (ref) {
                      var header = ref.header
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c("span", _vm._g({}, on), [
                                        _vm._v(_vm._s(header.text)),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Precipitation and/or snow cover for this date"
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.name",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center ms-2" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "py-auto",
                                attrs: { to: "/sites/" + item.id },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-sm font-weight-normal text-body",
                                    staticStyle: { "text-decoration": "none" },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.thirty_day_health",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-progress-linear",
                          {
                            staticStyle: { width: "100px" },
                            attrs: {
                              value: item.thirty_day_health,
                              height: "15",
                              "background-color": _vm.progressBackground(
                                item.thirty_day_health
                              ),
                              color: _vm.progressBarColor(
                                item.thirty_day_health
                              ),
                            },
                          },
                          [
                            _c("div", { staticClass: "text-center" }, [
                              _vm._v(
                                _vm._s(Math.ceil(item.thirty_day_health)) + "%"
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.day_health",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "site-wider-slot" },
                          [
                            item.has_cases !== 0
                              ? [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-chip",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: _vm.getColor(
                                                            item.day_health
                                                          ),
                                                          dark: "",
                                                        },
                                                      },
                                                      "v-chip",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          Math.round(
                                                            item.day_health
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("span", {
                                                      staticClass: "badge-dot",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Cases found, health adjusted by " +
                                            _vm._s(
                                              _vm.formatNumber(
                                                item.case_pct_of_dc_size_kw
                                              )
                                            ) +
                                            " percent"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        color: _vm.getColor(item.day_health),
                                        dark: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(Math.round(item.day_health)) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.ghi",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      item.ghi
                                        ? _c(
                                            "img",
                                            _vm._g(
                                              {
                                                staticStyle: {
                                                  width: "25px",
                                                  height: "25px",
                                                },
                                                attrs: {
                                                  src: require("@/assets/img/" +
                                                    _vm.sunshineVal(item.ghi) +
                                                    ".png"),
                                                },
                                              },
                                              on
                                            )
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.sunshinePopupVal(item.ghi))),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.precipitation_rate",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "img",
                                        _vm._g(
                                          {
                                            staticStyle: {
                                              width: "25px",
                                              height: "25px",
                                            },
                                            attrs: {
                                              src: require("@/assets/img/" +
                                                _vm.precipVal(item) +
                                                ".png"),
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.precipPopupVal(item))),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.controls",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.inv_issue
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "red",
                                  "padding-top": "15px",
                                  "padding-left": "15px",
                                },
                              },
                              [
                                _c("span", { staticClass: "material-icons" }, [
                                  _vm._v(" warning_amber "),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.energy_yield_var",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s((item.energy_yield_var * 100).toFixed(2)) +
                            "% "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.monitoring_url",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      item.monitoring_url
                                        ? _c(
                                            "img",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  src: require("@/assets/img/" +
                                                    item.monitoring_system +
                                                    ".png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.openWindow(
                                                      item.monitoring_url
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            )
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Monitoring Link")])]
                        ),
                        [
                          !item.monitoring_url
                            ? _c("v-icon", { attrs: { disabled: "" } }, [
                                _vm._v("mdi-web-box"),
                              ])
                            : _vm._e(),
                        ],
                      ]
                    },
                  },
                  {
                    key: "item.dots_menu",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              transition: "slide-y-transition",
                              "offset-y": "",
                              "offset-x": "",
                              "min-width": "300",
                              "max-width": "300",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "text-body",
                                              attrs: {
                                                icon: "",
                                                ripple: false,
                                                small: "",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "material-icons-round",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v(" mdi-dots-vertical ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "pa-3" },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass:
                                      "\n                   pa-4\n                   list-item-hover-active\n                   d-flex\n                   align-items-center\n                   py-1\n                   my-1\n                   border-radius-md\n                 ",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openWindow(item.site_plans)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "material-icons-round text-body",
                                        attrs: { id: "main_prof", size: "20" },
                                      },
                                      [_vm._v("mdi-floor-plan")]
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "pa-0" },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "\n                             text-sm\n                             font-weight-normal\n                             ms-2\n                             ma-auto\n                             text-typo\n                           ",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " View Site Plans "
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass:
                                      "\n                   pa-4\n                   list-item-hover-active\n                   d-flex\n                   align-items-center\n                   py-1\n                   my-1\n                   border-radius-md\n                 ",
                                    on: {
                                      click: function ($event) {
                                        return _vm.startNotes(item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "material-icons-round text-body",
                                        attrs: { id: "logout", size: "20" },
                                      },
                                      [_vm._v("mdi-note-multiple")]
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "pa-0" },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "\n                             text-sm\n                             font-weight-normal\n                             ms-2\n                             ma-auto\n                             text-typo\n                           ",
                                                    },
                                                    [_vm._v(" View Notes ")]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    staticClass:
                                      "\n                   pa-4\n                   list-item-hover-active\n                   d-flex\n                   align-items-center\n                   py-1\n                   my-1\n                   border-radius-md\n                 ",
                                    on: {
                                      click: function ($event) {
                                        return _vm.startCases(item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass:
                                          "material-icons-round text-body",
                                        attrs: { id: "logout", size: "20" },
                                      },
                                      [_vm._v("mdi-briefcase")]
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      { staticClass: "pa-0" },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c("v-col", [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "\n                             text-sm\n                             font-weight-normal\n                             ms-2\n                             ma-auto\n                             text-typo\n                           ",
                                                    },
                                                    [_vm._v(" View Cases ")]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "expanded-item",
                    fn: function (ref) {
                      var headers = ref.headers
                      var item = ref.item
                      return [
                        _c(
                          "td",
                          { attrs: { colspan: headers.length } },
                          [
                            _c("datatable-inverters", {
                              attrs: {
                                site_id: item.id,
                                site: item,
                                componentProps: _vm.componentProps,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "card-padding" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "6", lg: "3" },
                    },
                    [
                      _c("span", { staticClass: "text-body me-3 text-sm" }, [
                        _vm._v("Items per page:"),
                      ]),
                      _c("v-text-field", {
                        staticClass:
                          "\n               font-size-input\n               placeholder-lighter\n               text-color-light\n               input-alternative input-focused-alternative input-icon\n             ",
                        attrs: {
                          "hide-details": "",
                          type: "number",
                          outlined: "",
                          min: "-1",
                          max: "15",
                          "background-color": "rgba(255,255,255,.9)",
                          color: "rgba(0,0,0,.6)",
                          light: "",
                          value: _vm.itemsPerPage,
                          placeholder: "Items per page",
                        },
                        on: { input: _vm.updateItemsPerPage },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto d-flex justify-end",
                      attrs: { cols: "6" },
                    },
                    [
                      _c("v-pagination", {
                        staticClass: "pagination",
                        attrs: {
                          "prev-icon": "fa fa-angle-left",
                          "next-icon": "fa fa-angle-right",
                          color: "#D81B60",
                          length: _vm.pageCount,
                          circle: "",
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showNotesModal
        ? _c("notes-modal", {
            attrs: { site_id: this.selectedSite },
            model: {
              value: _vm.showNotesModal,
              callback: function ($$v) {
                _vm.showNotesModal = $$v
              },
              expression: "showNotesModal",
            },
          })
        : _vm._e(),
      _vm.showCasesModal
        ? _c("cases-modal", {
            attrs: { site_id: this.caseSite },
            model: {
              value: _vm.showCasesModal,
              callback: function ($$v) {
                _vm.showCasesModal = $$v
              },
              expression: "showCasesModal",
            },
          })
        : _vm._e(),
      _c("site-wizard", {
        attrs: { wizardData: _vm.wizardData },
        on: { close: _vm.closeSiteWizard },
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
      _c("validate-wizard", {
        attrs: {
          date: _vm.componentProps.date,
          userId: this.$store.getters.currentUser.id,
          checkTypeName: this.filter + "_" + this.subFilter,
          changeFlag: _vm.changeFlag,
        },
        on: { close: _vm.closeValidateWizard },
        model: {
          value: _vm.showValidateWizard,
          callback: function ($$v) {
            _vm.showValidateWizard = $$v
          },
          expression: "showValidateWizard",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }